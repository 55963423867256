import React from 'react'
import { Redirect } from 'react-router-dom'
import { FuseUtils } from '@fuse/index'
import { LoginConfig } from '../main/content/login/LoginConfig'
import { DashboardAppConfig } from '../main/content/dashboard/DashboardAppConfig'
import { OMSAppConfig, DistributorOMSConfig } from '../main/content/oms/OMSAppConfig'
import {
  InventoryAppConfig,
  VendorsAppConfig,
  UsersBlockConfig
} from '../main/content/inventory/InventoryAppConfig'
import { SalesAppConfig } from '../main/content/sales/SalesAppConfig'
import { UploadsAppConfig } from '../main/content/uploads/UploadsAppConfig'
import { HelpAppConfig } from '../main/content/help/HelpAppConfig'
import { SettingsAppConfig } from '../main/content/settings/SettingsAppConfig'
import { NotificationsAppConfig } from '../main/content/notifications/NotificationsAppConfig'
import { UsersManagementAppConfig } from '../main/content/users-management/UsersManagementAppConfig'

const routeConfigs = [
  DashboardAppConfig,
  OMSAppConfig,
  DistributorOMSConfig,
  InventoryAppConfig,
  SalesAppConfig,
  VendorsAppConfig,
  UsersBlockConfig,
  UploadsAppConfig,
  HelpAppConfig,
  SettingsAppConfig,
  NotificationsAppConfig,
  UsersManagementAppConfig,
  LoginConfig
]

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
  }
]
